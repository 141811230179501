body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "WorkSans";
  src: local("WorkSans"),
        url("./fonts/WorkSans-VariableFont_wght.ttf") format("truetype")
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
        url("./fonts/Inter-VariableFont_slnt_wght.ttf") format("truetype")
}
